<template>
  <div class="pt-5">
    <section class="container">
      <div class="card shadow-sm border-0 mt-5">
        <div class="card-body">

          <div class="mb-4 d-flex justify-content-between">
            <h2>Inbox</h2>
          </div>
          <table class="table table-search">
            <thead>
              <tr>
                <th v-for="(header, idx) in headersShow" :key="idx">
                  {{ header.label }}
                </th>
                <th>
                  Pesan
                </th>
              </tr>
            </thead>
            <tbody>
              
              <tr v-for="(data, idxD) in inboxList" :key="idxD">
                <td v-for="(header, idxH) in headersShow" :key="idxH">
                  {{ data[header.prop] }}
                </td>
                <td>{{ getInboxPesan(data) }}</td>
              </tr>

              <tr v-if="(inboxList.length < 1 || inboxList === '') && !isLoading">
                <td colspan="4" class="text-center">Tidak Ada Data Inbox</td>
              </tr>

              <tr v-show="isLoading">
                <td colspan="4">
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border"></div>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>

        </div>

        <div class="d-flex px-3 justify-content-end">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item" v-for="(link, idx) in links" :key="idx">
                <a @click.prevent="toPage(link.label)" class="page-link" :class="{ disabled: !link.active }" href="#" aria-label="Previous" v-if="link.label === '&laquo; Previous'">
                  <span aria-hidden="true">&laquo;</span>
                </a>
                <a @click.prevent="toPage(link.label)" class="page-link" :class="{ disabled: !link.active }" href="#" aria-label="Next" v-else-if="link.label === 'Next &raquo;'">
                  <span aria-hidden="true">&raquo;</span>
                </a>
                <a @click.prevent="toPage(link.label)" class="page-link" :class="{ disabled: link.label == currPage }" href="#" v-else>{{ link.label }}</a>
              </li>
              <!-- <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li> -->
            </ul>
          </nav>
        </div>

      </div>
    </section>
    </div>
</template>

<script>
import api from './api.js'
import swal from 'sweetalert2'
import auth from '../../helpers/auth.js'

export default {
  name: 'Inbox',
  data () {
    return {
      inboxList: [],
      isLoading: false,
      total: null,
      currPage: null,
      links: [],
      headersShow: [
        {
          label: 'Kode Jabatan',
          prop: 'kode_jabatan'
        },
        {
          label: 'Nama Jabatan',
          prop: 'nama_jabatan'
        },
        {
          label: 'Tanggal',
          prop: 'inbox_datetime'
        }
      ],
    }
  },
  mounted () {
    this.getInboxList()
  },
  methods: {
    async getInboxList (page = null) {
      const user = auth.getUser()

      if (user === null || user === undefined || user === '') return ''
      try {
        const params = {
          email: user.email,
          token: auth.getSSOToken()
        }
        let nextPageUrl = null
        let res = {}
        if (page !== null) {
          nextPageUrl = `https://ejabatan.kemnaker.go.id/hit/api/users/inbox/index?page=${page}`
          res = await api.getInboxList(params, { Authorization: `Bearer ${auth.getToken()}` }, nextPageUrl)
        } else {
          res = await api.getInboxList(params, { Authorization: `Bearer ${auth.getToken()}` })
        }
        console.log('res', res)
        const data = res.data
        if (res.status === 204) {
          this.inboxList = []
          return ''
        }
        
        this.currPage = data.current_page
        this.total = data.total
        this.links = data.links
        this.inboxList = JSON.parse(JSON.stringify(data.data))
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Gagal mengambil data inbox'
        })
      }
    },
    toPage (page) {
      let newPage = null
      if (page === '&laquo; Previous' && page > 1) {
        newPage = page - 1
      } else if (page === 'Next &raquo;' && page < this.total) {
        newPage = page + 1
      } else {
        newPage = page
      }

      this.getInboxList(newPage)
    },
    getInboxPesan (data) {
      let pesanSunting = `Terimakasih sudah melakukan sunting untuk ${data.kode_jabatan} (${data.nama_jabatan})`
      let pesanUsulan = `Terimakasih sudah melakukan usulan`

      if (data.kode_jabatan !== null && data.kode_jabatan !== '' && data.kode_jabatan !== undefined) {
        return pesanSunting
      } else {
        return pesanUsulan
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
