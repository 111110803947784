import axios from 'axios'
const phpAddress = process.env.VUE_APP_PHP_API

const clientPhp = axios.create({
  baseURL: phpAddress,
  json: true
})

export default {
  async execute(method, resource, data, headers) {
    return clientPhp({
      method,
      url: resource,
      data,
      headers
    }).then(req => {
      return req
    })
  },
  getInboxList (params, headers) {
    return this.execute('post', `/api/users/inbox/index`, params, headers)
  }
}